import {
  useDashboardApi,
  useUserApi,
  useDeviceApi,
  useAuthApi,
  useAdminApi,
  useWeatherApi,
  useCameraApi
} from '@wision/api';

export default defineNuxtPlugin(() => {
  let cookies : string | undefined;
  if (import.meta.server) { 
    cookies = useRequestHeaders(['cookie']).cookie
  }

  const config = useRuntimeConfig();
  const params = [config.public.apiHost, import.meta.server, cookies] as const;

  const dashboard = useDashboardApi(...params);
  const user = useUserApi(...params);
  const auth = useAuthApi(...params);
  const admin = useAdminApi(...params);
  const weather = useWeatherApi(...params);
  const device = useDeviceApi(config.public.apiHost, 2, import.meta.server, cookies);
  const deviceV3 = useDeviceApi(config.public.apiHost, 3, import.meta.server, cookies);
  const camera = useCameraApi(config.public.apiHost, import.meta.server, cookies);

  return {
    provide: {
      api: {
        admin,
        auth,
        dashboard,
        device,
        deviceV3,
        user,
        weather,
        camera
      }
    }
  };
});
