import { default as accountsYR6Sc95CEEMeta } from "/home/runner/work/wision/wision/apps/wision/src/pages/admin/accounts.vue?macro=true";
import { default as users6YyFscU8KGMeta } from "/home/runner/work/wision/wision/apps/wision/src/pages/admin/users.vue?macro=true";
import { default as alarmmanagementvtVJJHaAnMMeta } from "/home/runner/work/wision/wision/apps/wision/src/pages/alarmmanagement.vue?macro=true";
import { default as _91dashboard_93XDGejsSslRMeta } from "/home/runner/work/wision/wision/apps/wision/src/pages/dashboard/[dashboard].vue?macro=true";
import { default as index8lq2zbGyYEMeta } from "/home/runner/work/wision/wision/apps/wision/src/pages/dashboard/index.vue?macro=true";
import { default as indexv0URtL4uEqMeta } from "/home/runner/work/wision/wision/apps/wision/src/pages/devicemanagement/index.vue?macro=true";
import { default as loginu2rrrIj4o2Meta } from "/home/runner/work/wision/wision/apps/wision/src/pages/login.vue?macro=true";
import { default as profileCSJZea7BocMeta } from "/home/runner/work/wision/wision/apps/wision/src/pages/profile.vue?macro=true";
import { default as indexWKeFWpz8lVMeta } from "/home/runner/work/wision/wision/apps/wision/src/pages/restore-password/index.vue?macro=true";
import { default as taskmanagementZR2HOB4GE0Meta } from "/home/runner/work/wision/wision/apps/wision/src/pages/taskmanagement.vue?macro=true";
import { default as component_45stubg1Zv9oBGJnMeta } from "/home/runner/work/wision/wision/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubg1Zv9oBGJn } from "/home/runner/work/wision/wision/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-accounts",
    path: "/admin/accounts",
    meta: {"middleware":["auth","lang"]},
    component: () => import("/home/runner/work/wision/wision/apps/wision/src/pages/admin/accounts.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: {"middleware":["auth","lang"]},
    component: () => import("/home/runner/work/wision/wision/apps/wision/src/pages/admin/users.vue")
  },
  {
    name: "alarmmanagement",
    path: "/alarmmanagement",
    meta: {"middleware":["auth","lang"]},
    component: () => import("/home/runner/work/wision/wision/apps/wision/src/pages/alarmmanagement.vue")
  },
  {
    name: "dashboard-dashboard",
    path: "/dashboard/:dashboard()",
    meta: { ...(_91dashboard_93XDGejsSslRMeta || {}), ...{"middleware":["auth","lang"]} },
    component: () => import("/home/runner/work/wision/wision/apps/wision/src/pages/dashboard/[dashboard].vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: { ...(index8lq2zbGyYEMeta || {}), ...{"middleware":["auth","lang"]} },
    component: () => import("/home/runner/work/wision/wision/apps/wision/src/pages/dashboard/index.vue")
  },
  {
    name: "devicemanagement",
    path: "/devicemanagement",
    meta: {"middleware":["auth","lang"]},
    component: () => import("/home/runner/work/wision/wision/apps/wision/src/pages/devicemanagement/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: {"middleware":["lang"]},
    component: () => import("/home/runner/work/wision/wision/apps/wision/src/pages/login.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: {"middleware":["auth","lang"]},
    component: () => import("/home/runner/work/wision/wision/apps/wision/src/pages/profile.vue")
  },
  {
    name: "restore-password",
    path: "/restore-password",
    meta: { ...(indexWKeFWpz8lVMeta || {}), ...{"middleware":["lang"]} },
    component: () => import("/home/runner/work/wision/wision/apps/wision/src/pages/restore-password/index.vue")
  },
  {
    name: "taskmanagement",
    path: "/taskmanagement",
    meta: {"middleware":["auth","lang"]},
    component: () => import("/home/runner/work/wision/wision/apps/wision/src/pages/taskmanagement.vue")
  },
  {
    name: component_45stubg1Zv9oBGJnMeta?.name,
    path: "/",
    component: component_45stubg1Zv9oBGJn
  },
  {
    name: component_45stubg1Zv9oBGJnMeta?.name,
    path: "/admin",
    component: component_45stubg1Zv9oBGJn
  }
]